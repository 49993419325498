import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import shadows from './shadows';
import customTheme from './variants';
import { createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';

const appTheme = createTheme({
  spacing: 4,
  breakpoints: breakpoints,
  components: overrides,
  typography: typography,
  shadows: shadows,
  palette: {
    primary: {
      main: blue[700],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[500],
      contrastText: '#FFF',
    },
  },
  name: customTheme.name,
  header: customTheme.header,
  sidebar: customTheme.sidebar,
  body: customTheme.body,
});

export default appTheme;
