import type { Components } from '@mui/material';

const overrides: Components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        tableLayout: 'fixed',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '.MuiTableCell-head': {
          padding: 16,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        boxSizing: 'border-box',
        wordBreak: 'break-word',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        width: 'fit-content',
      },
    },
  },
};

export default overrides;
