import type { ThemeOptions } from '@mui/material';
import { blue, grey, green } from '@mui/material/colors';

const customTheme: ThemeOptions = {
  name: 'Dark',
  header: {
    color: grey[500],
    background: '#FFFFFF',
  },
  sidebar: {
    color: grey[200],
    background: '#1B2430',
    header: {
      color: grey[200],
      background: '#232f3e',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#232f3e',
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    background: '#F7F9FC',
  },
};

export default customTheme;
